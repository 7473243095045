.about-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 40px 20px;
    color: #333;
    line-height: 1.6;
}

.about-container h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #2c3338;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid #343a40;
}

.about-container h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2c3338;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.about-container p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    color: #4a4a4a;
}

.about-container a {
    color: #1481e0;
    text-decoration: none;
    transition: color 0.2s ease;
}

.about-container a:hover {
    color: #0056b3;
    text-decoration: underline;
}

.about-section {
    background: white;
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .about-container {
        padding: 20px;
    }

    .about-container h1 {
        font-size: 2rem;
    }

    .about-container h3 {
        font-size: 1.3rem;
    }

    .about-container p {
        font-size: 1rem;
    }
} 