.panel-MainTitle {
    margin-top: 20;
    margin-bottom: 20;
    font-size: 25px;
}

.station-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;  
    min-height: 100vh;
    min-height: 100dvh;
    box-sizing: border-box;
    background-color: #ffffff;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .station-page {
        min-height: -webkit-fill-available;
        padding-bottom: max(env(safe-area-inset-bottom), 20px);
    }

    .station-card:last-child {
        margin-bottom: env(safe-area-inset-bottom);
    }
}

.station-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    margin: 1rem 0 0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    background: linear-gradient(to bottom, #2c3338, #343a40);
}

/* Station-specific styling */
div.bom-station .station-header {
    background: rgb(0, 51, 102);
}

div.vicports-station .station-header {
    background: #004ea8;
}

.station-title {
    flex: 1;
}

.station-title h1 {
    font-size: 2.5rem;
    margin: 0;
    color: #ffffff;
    font-weight: 500;
    line-height: 1.2;
}

.station-time {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.9);
    margin-top: 0.5rem;
    font-weight: 500;
}

/* Light text for dark headers */
.bom-station .station-title h1,
.bom-station .station-time,
.vicports-station .station-title h1,
.vicports-station .station-time {
    color: #ffffff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.station-logo {
    height: 80px;
    width: auto;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Station-specific logo sizes */
.vicports-station .station-logo {
    max-width: 140px;
}

.station-logo img {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

.age-warning {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 0;
    border-radius: 0 0 4px 4px;
    width: 100%;
}

.age-warning.note {
    background-color: #e1f5fe;
    color: #0277bd;
    border: 1px solid #b3e5fc;
}

.age-warning.caution {
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
}

.age-warning.note::before {
    content: "ℹ️";
    font-size: 1.1rem;
}

.age-warning.caution::before {
    content: "⚠️";
    font-size: 1.1rem;
}

.wind-summary {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.wind-value {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
}

.wind-value:not(:last-child) {
    border-right: 1px solid #eee;
}

.wind-label {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.wind-number {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
}

.station-card {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.75rem;
}

.station-card-header {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #eee;
}

.station-card-header h2 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
    font-weight: 500;
}

.station-card-body {
    padding: 1rem;
}

/* Remove card styling from wind trend section */
.station-card.wind-trend {
    box-shadow: none;
    background: none;
    margin-bottom: 0;
}

.station-card.wind-trend .station-card-header {
    display: none;
}

.station-card.wind-trend .station-card-body {
    padding: 1rem;
}

.station-card.wind-trend .station-card-header h2 {
    font-size: 1.2rem;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Current Conditions Styling */
.current-conditions {
    padding: 1rem 0;
}

.current-time {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.75rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    background-color: #f8f9fa;
    flex-wrap: wrap;
}

.current-time.note {
    background-color: #e1f5fe;
    color: #0277bd;
}

.current-time.caution {
    background-color: #fff3cd;
    color: #856404;
}

.current-time-label {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    opacity: 0.8;
}

.current-time-value {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;
}

.age-counter {
    color: inherit;
    font-size: 0.9rem;
    opacity: 0.9;
}

.current-values {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 0.5rem;
}

.current-values.three-columns .current-value {
    flex: 1;
}

.current-values.two-columns .current-value {
    flex: 0 0 calc(50% - 1rem);
}

.current-values.two-columns {
    justify-content: center;
}

.current-value {
    text-align: center;
}

.current-label {
    font-size: 0.9rem;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
}

.current-number {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333;
    line-height: 1;
    margin-bottom: 0.25rem;
}

.current-unit {
    font-size: 0.9rem;
    color: #666;
    text-transform: uppercase;
}

.current-direction {
    font-size: 1.8rem;
    font-weight: 500;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.direction-cardinal {
    font-weight: 600;
}

.direction-arrow {
    color: #666;
}

.direction-degrees {
    font-size: 1.2rem;
    color: #666;
}