body {
    margin: 0;
    padding: 0;
    height: 100%;
    height: 100dvh;
    width: 100%;
    overflow: hidden;
    background-color: #f8f9fa;
}

html {
    height: 100%;
    height: 100dvh;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
}

.navbar {
    padding-top: env(safe-area-inset-top);
    background: linear-gradient(to bottom, #2c3338, #343a40);
    position: relative;
    z-index: 1000;
    height: calc(70px + env(safe-area-inset-top));
    flex: 0 0 auto;
}

#map {
    position: relative;
    flex: 1;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.ol-viewport {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    width: 100% !important;
}

.ol-popup {
    margin-bottom: env(safe-area-inset-bottom);
    max-height: calc(100dvh - 100px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    display: flex;
    flex-direction: column;
}

.ol-popup-content {
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.ol-popup .btn {
    margin-bottom: 0;
}

/* Hide zoom controls on mobile */
@media (max-width: 768px) {
    .ol-zoom {
        display: none;
    }
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    html {
        height: -webkit-fill-available;
    }
    
    body {
        height: -webkit-fill-available;
    }
    
    #root {
        height: -webkit-fill-available;
    }
    
    #map {
        height: 100%;
    }
    
    .ol-viewport {
        bottom: env(safe-area-inset-bottom) !important;
    }
}