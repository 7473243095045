body {
    margin: 0;
    padding: 0;
    padding-top: calc(70px + env(safe-area-inset-top));
}

.navbar {
    background: linear-gradient(to bottom, #2c3338, #343a40) !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: env(safe-area-inset-top) 0 0.5rem;
    height: calc(70px + env(safe-area-inset-top));
    margin: 0;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-radius: 0;
}

.navbar-brand {
    color: white !important;
    font-weight: 600;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    padding: 0;
    letter-spacing: 0.5px;
}

.navbar-brand span {
    position: relative;
    top: 1px;
}

.navbar-brand img {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
    transition: transform 0.2s ease;
}

.navbar-brand:hover img {
    transform: scale(1.05);
}

.navbar .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.navbar-nav {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.9) !important;
    font-weight: 500;
    padding: 0.5rem 1rem !important;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    font-size: 1rem;
    margin-right: 0;
    background-color: rgba(255, 255, 255, 0.1);
}

.navbar-nav .nav-link:hover {
    color: white !important;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-1px);
}

.nav-link-highlight {
    background-color: rgba(255, 255, 255, 0.1);
    margin-left: 0.5rem;
}

.nav-link-highlight:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.navbar-toggler {
    border: none;
    padding: 0.5rem;
    margin-right: 0.5rem;
    transition: background-color 0.2s ease;
    color: transparent;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-toggler:focus {
    box-shadow: none;
    outline: none;
}

.navbar-toggler:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    width: 24px;
    height: 24px;
}

@media (max-width: 991.98px) {
    .navbar {
        height: auto;
        padding: 0.75rem 0;
    }

    .navbar-collapse {
        background: linear-gradient(to bottom, #343a40, #2c3338);
        padding: 1rem;
        margin: 0.5rem -1rem -0.75rem;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .navbar-nav {
        gap: 0.25rem;
        padding-right: 1rem;
    }

    .navbar-nav .nav-link {
        margin-right: 0;
        text-align: center;
        padding: 0.75rem 1rem !important;
    }

    .nav-link-highlight {
        margin-left: 0;
        margin-top: 0.5rem;
    }

    .navbar .container {
        padding: 0 1rem;
    }
}

.card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1rem;
}

.card-title {
    color: #333;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
}

.card-body {
    padding: 1.25rem;
}

#mini-graph {
    padding: 5px 0px 10px 5px;
    text-align: center;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    background-color: white;
} 