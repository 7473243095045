.ol-popup {
    position: absolute;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    padding: 0px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
    font-size: 12px;
}
.ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.ol-popup:after {
    border-top-color: #f5f5f5;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}
.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

#popup-summary th {
    padding: 5px 5px 5px 15px;
}


.panel {
        margin-bottom: 0px;
}

.station-popup {
    min-width: 300px;
    max-width: 400px;
}

.station-popup .card {
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Remove default Card.Header background */
.station-popup .card-header {
    padding: 0;
    background: none;
    border: none;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    min-height: 64px;
    background: linear-gradient(to bottom, #2c3338, #343a40);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

/* Station-specific styling */
.bom-station .popup-header {
    background: rgb(0, 51, 102);
}

.vicports-station .popup-header {
    background: #004ea8;
}

.popup-title h2 {
    font-size: 1.5rem;
    margin: 0;
    color: #ffffff;
    font-weight: 500;
    line-height: 1.2;
}

.current-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 1rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #eee;
    gap: 0.25rem;
}

.current-time.note {
    background-color: #e1f5fe;
    color: #0277bd;
    border-bottom: 1px solid #b3e5fc;
}

.current-time.caution {
    background-color: #fff3cd;
    color: #856404;
    border-bottom: 1px solid #ffeeba;
}

.current-time-label {
    font-size: 0.85rem;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.current-time-value {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
}

.current-time.note .current-time-value,
.current-time.caution .current-time-value {
    color: inherit;
}

.age-counter {
    font-size: 0.9rem;
    color: #666;
}

.current-time.note .age-counter,
.current-time.caution .age-counter {
    color: inherit;
    opacity: 0.85;
}

/* Light text for dark headers */
.bom-station .popup-title h2,
.vicports-station .popup-title h2 {
    color: #ffffff;
}

.observed-time {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
    margin-top: 0.5rem;
    font-weight: 500;
}

/* Light text for dark headers */
.bom-station .popup-title h2,
.bom-station .observed-time,
.vicports-station .popup-title h2,
.vicports-station .observed-time {
    color: #ffffff;
}

.bom-station .observed-time,
.vicports-station .observed-time {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.popup-logo {
    height: 100%;
    width: auto;
    max-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -0.75rem 0;
}

/* Station-specific logo sizes */
.vicports-station .popup-logo {
    max-width: 90px;
}

.popup-logo img {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

.popup-body {
    padding: 0.75rem 1rem 0;
}

.wind-summary {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;
    width: 100%;
    min-width: 280px;
}

.wind-summary.three-columns .wind-value {
    flex: 1;
    min-width: 90px;
}

.wind-summary.two-columns .wind-value {
    flex: 0 0 calc(50% - 0.5rem);
    min-width: 120px;
}

.wind-summary.two-columns {
    justify-content: center;
    gap: 1rem;
}

.wind-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 0.25rem;
    white-space: nowrap;
}

.wind-value:not(:last-child) {
    border-right: 1px solid #eee;
}

.wind-summary.two-columns .wind-value:first-child {
    border-right: 1px solid #eee;
}

.wind-label {
    font-size: 0.85rem;
    color: #666;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: nowrap;
}

.wind-number {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
    min-width: 70px;
    text-align: center;
}

.popup-details {
    margin-top: 1rem;
    font-size: 0.9rem;
}

.popup-details th {
    color: #666;
    font-weight: 500;
    width: 40%;
}

.popup-details td {
    color: #333;
}

.view-details-link {
    display: inline-block;
    margin: 1rem auto 0;
    padding: 0.5rem 1.25rem;
    color: #666;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    transition: all 0.2s ease;
    width: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.view-details-link:hover {
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ced4da;
    color: #495057;
}

/* Station-specific button styling */
.bom-station .view-details-link {
    color: rgb(0, 51, 102);
    border-color: rgba(0, 51, 102, 0.2);
}

.bom-station .view-details-link:hover {
    background-color: rgba(0, 51, 102, 0.05);
    border-color: rgba(0, 51, 102, 0.3);
}

.vicports-station .view-details-link {
    color: #004ea8;
    border-color: rgba(0, 78, 168, 0.2);
}

.vicports-station .view-details-link:hover {
    background-color: rgba(0, 78, 168, 0.05);
    border-color: rgba(0, 78, 168, 0.3);
}

.age-warning {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.age-warning.note {
    background-color: #e1f5fe;
    color: #0277bd;
    border-bottom: 1px solid #b3e5fc;
}

.age-warning.caution {
    background-color: #fff3cd;
    color: #856404;
    border-bottom: 1px solid #ffeeba;
}

.age-warning.note::before {
    content: "ℹ️";
    font-size: 1rem;
}

.age-warning.caution::before {
    content: "⚠️";
    font-size: 1rem;
}